import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { TreeContextProvider } from './context/TreeContext';
import { DisplaySettingsProvider } from './context/TreeSettingsContext';

import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';

const root = createRoot(document.getElementById('root'));

const renderApp = async () => {
    await i18n.init();
    root.render(
        <BrowserRouter>
            <TreeContextProvider>
                <UserProvider>
                    <DisplaySettingsProvider >
                        <I18nextProvider i18n={i18n}>
                            <App />
                        </I18nextProvider>
                    </DisplaySettingsProvider >
                </UserProvider>
            </TreeContextProvider>
        </BrowserRouter>
    );
};

renderApp();
reportWebVitals();