import React, { createContext, useContext, useState } from 'react';

// Создаем контекст
const DisplaySettingsContext = createContext();

// Создаем провайдер для контекста
export const DisplaySettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        showBirthDate: true,
        showDeathDate: true,
        showBirthPlace: true,
        showDeathPlace: true,
        showLastNameAtBirth: true,
        showCurrentLastName: true,
        showAllLastNames: true,
        showPatronymic: true,
        showPhoto: true,
    });

    const setGroupSettings = (group) => {
        switch (group) {
            case 'minimal':
                setSettings({
                    showBirthDate: true,
                    showDeathDate: true,
                    showBirthPlace: false,
                    showDeathPlace: false,
                    showLastNameAtBirth: false,
                    showCurrentLastName: false,
                    showAllLastNames: false,
                    showPatronymic: false,
                    showPhoto: false,
                });
                break;
            case 'detailed':
                setSettings({
                    showBirthDate: true,
                    showDeathDate: true,
                    showBirthPlace: true,
                    showDeathPlace: true,
                    showLastNameAtBirth: true,
                    showCurrentLastName: true,
                    showAllLastNames: true,
                    showPatronymic: true,
                    showPhoto: true,
                });
                break;
            default:
                break;
        }
    };

    return (
        <DisplaySettingsContext.Provider value={{ settings, setSettings, setGroupSettings }}>
            {children}
        </DisplaySettingsContext.Provider>
    );
};

// Хук для использования контекста
export const useDisplaySettings = () => {
    return useContext(DisplaySettingsContext);
};