// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import de from './de.json';
import ru from './ru.json';
import uk from './uk.json';

import enLending from './lending/en-lending.json';
import esLending from './lending/es-lending.json';
import frLending from './lending/fr-lending.json';
import deLending from './lending/de-lending.json';
import ruLending from './lending/ru-lending.json';
import ukLending from './lending/uk-lending.json';

import ruButton from './button/ru-button.json';
import ukButton from './button/uk-button.json';
import enButton from './button/en-button.json';
import esButton from './button/es-button.json';
import frButton from './button/fr-button.json';
import deButton from './button/de-button.json';

import ruNavbar from './navbar/ru-navbar.json';
import ukNavbar from './navbar/uk-navbar.json';
import enNavbar from './navbar/en-navbar.json';
import esNavbar from './navbar/es-navbar.json';
import frNavbar from './navbar/fr-navbar.json';
import deNavbar from './navbar/de-navbar.json';

import ruSearch from './search/ru-search.json';
import ukSearch from './search/uk-search.json';
import enSearch from './search/en-search.json';
import esSearch from './search/es-search.json';
import frSearch from './search/fr-search.json';
import deSearch from './search/de-search.json';

import ruInput from './input/ru-input.json';
import ukInput from './input/uk-input.json';
import enInput from './input/en-input.json';
import esInput from './input/es-input.json';
import frInput from './input/fr-input.json';
import deInput from './input/de-input.json';

import ruCeo from './seo/ru.json';
import ukCeo from './seo/uk.json';
import enCeo from './seo/en.json';

import ruZapros from './zapros/ru.json';



i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources: {
        en: { translation: en,
            lending: enLending,
            button: enButton,
            navbar: enNavbar,
            search: enSearch,
            input: enInput,
            ceo: enCeo
        
        },    
        es: { translation: es,
            lending: esLending,
            button: esButton,
            navbar: esNavbar,
            search: esSearch,
            input: esInput
         },
        fr: { translation: fr,
            lending: frLending,
            button: frButton,
            navbar: frNavbar,
            search: frSearch,
            input: frInput
         },
        de: { translation: de,
            lending: deLending,
            button: deButton,
            navbar: deNavbar,
            search: deSearch,
            input: deInput
         },
        ru: { translation: ru,
            lending: ruLending,
            button: ruButton,
            navbar: ruNavbar,
            search: ruSearch,
            input: ruInput,
            seo: ruCeo,
            zapros: ruZapros
         },
        uk: { translation: uk,
            lending: ukLending,
            button: ukButton,
            navbar: ukNavbar,
            search: ukSearch,
            input: ukInput,
            seo: ukCeo
         },
    },
    fallbackLng: 'es',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
