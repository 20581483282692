import { toast, Slide } from "react-toastify";

// Функция для отображения уведомлений
export const showToast = (message, type) => {
  const commonSettings = {
    position: "top-right",       // Позиция в правом верхнем углу
    autoClose: 4000,             // Автоматическое закрытие через 4 секунды
    hideProgressBar: false,      // Прогресс-бар виден
    closeOnClick: true,          // Закрытие уведомления по клику
    pauseOnHover: true,          // Остановка закрытия при наведении
    draggable: true,             // Возможность перетаскивания
    transition: Slide,           // Анимация — слайд
  };

  switch (type) {
    case 'success':
      toast.success(message, {
        ...commonSettings,
        autoClose: 3000,
      });
      break;
    case 'error':
      toast.error(message, {
        ...commonSettings,
        autoClose: 5000,
      });
      break;
    case 'info':
      toast.info(message, {
        ...commonSettings,
        autoClose: 4000,
      });
      break;
    case 'warning':
      toast.warning(message, {
        ...commonSettings,
        autoClose: 4000,
      });
      break;
    default:
      toast(message, {
        ...commonSettings,
      });
      break;
  }
};
