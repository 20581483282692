import React, { createContext, useContext, useState, useEffect } from 'react';
import {deleteTree, getTreeList, updateTree, getTree, importTree, postTree} from '../Api/Tree/tree/treeRequests';
import { Modal } from 'antd';
const TreeContext = createContext();

export const useTreeContext = () =>{
    return useContext(TreeContext);
}

export const TreeContextProvider = ({ children }) => {
    const initialTree = {
        name: '',
        description: '',
        createdDate: '',
        lastModifiedDate: '',
        isPublic: '',
        personCount: '',
        id: '',
        owner: '',
        links: [],
        persons: [],
        tasks: [],
        data: null,
    };
    const [treeList, setTreeList] = useState([]);
    const [selectedTree, setSelectedTree] = useState(initialTree);

    const loadStoredData = (type) => {
        // Получим данные из localStorage
        const storedData = localStorage.getItem(type);
      
        // Проверим, существуют ли данные и не undefined
        if (!storedData || storedData === undefined) {
          // Если данных нет, вернем null
          return null;
        }
      
        // Попробуем преобразовать данные в JSON
        try {
          return JSON.parse(storedData);
        } catch (error) {
          // Если данные некорректны, выведем сообщение об ошибке и вернем null
          console.error(`Ошибка при загрузке ${type} из localStorage:`, error);
          return null;
        }
      };

    const saveToLocalStorage = (data, type) => {
        localStorage.setItem(type, JSON.stringify(data));
    };
    
    const getTrees = async () => {
        try {
            const trees = await getTreeList();
            if (trees !== null) {
                setTreeList(trees);
                saveToLocalStorage(trees, 'trees');
                return trees; // или другое значение, указывающее на успешное выполнение запроса
            } else {
                // Дополнительная обработка в случае, если данные пусты или произошла ошибка при запросе
                console.error('Получен пустой список деревьев или произошла ошибка при запросе');
                return null; // или другое значение, указывающее на ошибку
            }
        } catch (error) {
            // Обработка ошибок при выполнении запроса
            console.error('Произошла ошибка при выполнении запроса:', error.message);
            return null; // или другое значение, указывающее на ошибку
        }
    };

    const handleUpdateTree = async (tree) => {
        const updatedTree = await updateTree(tree)
        
        handleSelectTree(updatedTree);
    }

    const handleSelectTree = async (tree) => {
        setSelectedTree(tree);
        saveToLocalStorage(tree, 'tree');
    };

    const handleDeselectTree = () => {
        setSelectedTree(null);
        localStorage.removeItem('tree');
    };

    const handleGetTree = async (treeId) => {
        try {
            const tree = await getTree(treeId)
            handleSelectTree(tree);
        } catch (error) {
            console.error('Ошибка при удалении древа:', error);
        }
    }

    const handleCreateTree = async (treeData) => {
        try {
            const tree = await postTree(treeData);
            handleSelectTree(tree);
        } catch (error) {
            console.error('Ошибка при удалении древа:', error);
        }
    };
    const handleDeleteTree = async (treeId) => {
        Modal.confirm({
            title: 'Подтверждение удаления',
            content: 'Вы действительно хотите удалить древо?',
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: async () => {
                try {
                    // Если текущее дерево совпадает с удаляемым, отменяем выбор
                    if (selectedTree && selectedTree.id === treeId) {
                        await handleDeselectTree();
                    }
                    
                    // Удаляем древо
                    await deleteTree(treeId);
                    
                    // Обновляем список деревьев
                    await getTrees();
                    
                } catch (error) {
                    console.error('Ошибка при удалении древа:', error);
                }
            },
            onCancel: () => {
                console.log('Удаление отменено пользователем');
            }
        });
    };    

    const logoutTree = () => {
        handleDeselectTree(); // Сбросить выбранное дерево
        setTreeList([]); // Очистить список деревьев
        localStorage.removeItem('trees');
    };

    useEffect(() => {
        // При монтировании компонента
    
        const storedTrees = loadStoredData('trees');
        const storedTree = loadStoredData('tree');

        if (storedTrees) {
            setTreeList(storedTrees);
        }
        if (storedTree) {
            setSelectedTree(storedTree);
        }
    }, []);

    return (
        <TreeContext.Provider
            value={{
                treeList,
                selectedTree,
                handleSelectTree,
                handleDeselectTree,
                handleDeleteTree,
                getTrees,
                logoutTree,
                handleCreateTree,
                handleGetTree,
                handleUpdateTree,
                persons: selectedTree?.persons || [],
                links: selectedTree?.links || []
            }}
        >
            {children}
        </TreeContext.Provider>
    );
}
