import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { createAcount } from '../Api/User/acountRequests';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const initialUser = {
        data: {
            id: '', alias: '', login: '', permissions: '', photoPath: '',
            lastName: '', firstName: '', middleName: '', address: '',
            phoneNumber: '', roles: [], passportData: {
                passportNumber: '', passportIssuedBy: '', passportIssueDate: '',
                signatureImage: ''
            },
        },
        token: '', isLoggedIn: false,
    };

    const [user, setUser] = useState(initialUser);

    useEffect(() => {
        const userDataJSON = localStorage.getItem('userData');
        if (userDataJSON) {
            const userData = JSON.parse(userDataJSON);
            setUser({
                ...initialUser,
                isLoggedIn: true,
                token: userData.token,
                data: { ...initialUser.data, ...userData.data }
            });
        }
    }, []);

    const login = (userData, token) => {
        const updatedUser = { isLoggedIn: true, token, data: userData };
        setUser(updatedUser);
        localStorage.setItem('userData', JSON.stringify(updatedUser));
    };

    const logout = () => {
        setUser(initialUser);
        localStorage.removeItem('userData');
    };

    const updateUserData = useCallback((newUserData) => {
        setUser(prevUser => ({
            ...prevUser, 
            data: { ...prevUser.data, ...newUserData }
        }));
    }, [setUser]);

    const handleSubmit = async (newUser) => {
        const updatedData = await createAcount(newUser);
        const updatedUser = { ...user, data: updatedData };
        setUser(updatedUser);
        localStorage.setItem('userData', JSON.stringify(updatedUser));
    };

    const handleChange = useCallback((event) => {
        const { name, value } = event.target;
        updateUserData({ [name]: value });
    }, [updateUserData]);

    const handleAvatar = async (photoPath) => {
        await handleSubmit({...user.data, photoPath: photoPath}); // отправляем данные после обновления
    };

    return (
        <UserContext.Provider value={{
            user,
            login,
            logout,
            updateUserData,
            handleChange,
            handleSubmit,
            handleAvatar}}
        >
            {children}
        </UserContext.Provider>
    );
};
