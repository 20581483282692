import { apiPut, apiGet } from '../apiClient';

export const createAcount = async (acountData) => {
    const url = `/account/edit`;
    return apiPut(url, acountData, 'Account updated successfully!', 'Failed to update account');
};

// Получение статистики по пользователям
export const getUserStatistics = async () => {
    const url = `/users/statistics`;
    return apiGet(url, 'Order statistics retrieved successfully!', 'Failed to retrieve order statistics');
};
