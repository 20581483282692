// apiClient.js
import axios from 'axios';
import { HEADERS } from './settings';
import { showToast } from '../functions/Notifications/toastNotifications';

const domain = process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
  baseURL: domain,
  headers: HEADERS()
});

// Функция для обработки запросов
const handleRequest = async (request, successMessage, errorMessage) => {
  try {
    const response = await request;

    // Проверяем, если статус не успешен
    if (response.status !== 200) {
      throw new Error(errorMessage || 'Ошибка при выполнении запроса');
    }

    // Если сообщение об успехе передано, показываем уведомление
    if (successMessage) {
      showToast(successMessage, 'success');
    }

    return response.data;
  } catch (error) {
    // Показываем уведомление об ошибке
    showToast(errorMessage || error.message, 'error');
    console.error('Error:', error.message);
    throw error;
  }
};

// GET запрос
export const apiGet = (url, successMessage, errorMessage, params = {}) => {
  return handleRequest(apiClient.get(url, { params }), successMessage, errorMessage);
};

// POST запрос
export const apiPost = (url, data, successMessage, errorMessage) => {
  return handleRequest(apiClient.post(url, data), successMessage, errorMessage);
};

// PUT запрос
export const apiPut = (url, data, successMessage, errorMessage) => {
  return handleRequest(apiClient.put(url, data), successMessage, errorMessage);
};

// DELETE запрос
export const apiDelete = (url, data, successMessage, errorMessage) => {
  return handleRequest(apiClient.delete(url, { data }), successMessage, errorMessage);
};
