//реакт компонент
import React from 'react';
import styles from './Loading.module.css';

const Loading = () => {
    return (
            <div className={styles.loadingOverlay}>
                <div className={styles.loadingImage}></div>
            </div>
        );
}

export default Loading;