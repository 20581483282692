const getStoredUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return userData;
};

const getStoredToken = () => {
    const storedUserData = getStoredUserData();
    return storedUserData.token || '';
};

const getStoredTree = () => {
    const tree = JSON.parse(localStorage.getItem('tree')) || {};
    return tree;
};

const getStoredTreeId = () => {
    const storedTree = getStoredTree();
    return storedTree.id;
};

const getHeaders = () => {
    return {
        'Authorization': `Bearer ${getStoredToken()}`,
        'Content-Type': 'application/json',
    };
};

export const TOKEN = () => getStoredToken();
export const TREE_ID = () => getStoredTreeId();
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const HEADERS = () => getHeaders();