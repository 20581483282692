import { apiGet, apiPost, apiPut, apiDelete } from '../../apiClient';

// Удаление дерева
export const deleteTree = async (treeId) => {
  const url = `/genealogy/tree/${treeId}`;
  return apiDelete(url, null, 'Tree deleted successfully!', 'Failed to delete tree');
};

// Получение дерева
export const getTree = async (selectedTree) => {
  const url = `/genealogy/tree/${selectedTree}`;
  return apiGet(url, 'Tree data retrieved successfully!', 'Failed to retrieve tree data');
};

// Получение списка деревьев
export const getTreeList = async () => {
  const url = `/genealogy/tree/list`;
  return apiGet(url, 'Tree list retrieved successfully!', 'Failed to retrieve tree list');
};

// Импорт дерева
export const importTree = async (treeData) => {
  const url = `/genealogy/tree/import`;
  return apiPost(url, treeData, 'Tree imported successfully!', 'Failed to import tree');
};

// Создание нового дерева
export const postTree = async (treeData) => {
  const url = `/genealogy/tree/new`;
  return apiPost(url, treeData, 'Tree created successfully!', 'Failed to create tree');
};

// Обновление дерева
export const updateTree = async (treeData) => {
  const url = `/genealogy/tree/${treeData.id}`;
  return apiPut(url, treeData, 'Tree updated successfully!', 'Failed to update tree');
};
